import recallServices from '../../../../../services/provider/recall'
import _ from 'lodash'
import { VueEditor } from 'vue2-editor'
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'
import VueTagsInput from '@johmun/vue-tags-input'
import Vue from 'vue'
import tinymce from 'vue-tinymce-editor'
import { OUTPUT_SHORTDATE_FORMAT } from '../../../../../misc/commons'
import {getCommunicationType} from '../../../../../misc/genericFunctions'
import ViewModal from '../../../../../components/part/shared/PreviewViewModal'
Vue.component('tinymce', tinymce)
export default {
    props: {
        communicationId: { type: String, default: null },
        isProviderCreatedRecall: { type: Boolean, default: false },
        providerCommunicationId: {type: String, default: ''},
        communicationType: {type: String, default: ''},
        disabledDelegate: {type: Boolean, default: false},
        providerEventStatus: {type: String, default: ''},
        completedRecall: {type: Boolean, default: false},
    },
    components: {
        VueEditor,
        Multiselect,
        VueTagsInput,
        ViewModal
    },
    data() {
        return {
            OUTPUT_SHORTDATE_FORMAT: OUTPUT_SHORTDATE_FORMAT,
            height: 0,
            conversations: [],
            conversationName: null,
            conversationMsg: null,
            previousValue: null,
            userid: null,
            viewModalKey: 0,
            isShowInternal: false,
            value: [],
            options: [],
            admin: [],
            emailError: '',
            domainError: '',
            emailDuplicateError: '',
            providerInfoMailList: [],
            supplierId: 0,
            items: null,
            item: {
                email: [],
                comment: null,
                OrganizationId: null,
                DelegatId: 0,
                files: new FormData()
            },
            conversationEditing: {
                id: null,
                name: null
            },
            messages: {},
            messagesF: [],

            messageContent: null,
            preloadConversationId: null,
            emailItem: {
                email_type: null,
                subject: null,
                content: null,
                parameters: null
            },
            bodyContent: null,
            theReplace: null,
            clicked: '',
            intrnalTabActive: false,
            showBtn: true,
            supplierTabActive: true,
            filesUpload: [],
            tag: '',
            tags: [],
            autocompleteItems: [],
            valueTag: [],
            hideattachement: 'false',
            isShowToolbarOptions: 'true',
            selectedDelegateId: '',
            tinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function (ed) {
                    ed.on('keydown', function (evt) {
                        if (evt.keyCode == 9) {
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                }

            },
            disabletoolbartinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                toolbar: false,
                menubar: false,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
            }
        }
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    created() {
        this.getProviderInfoMail()
        this.getEmailList()
        this.userid = this.$store.state.user.id
        if (this.$route.query.conId && this.$route.query.conId > 0) {
            this.$store.state.lastedRouteNotAuth=''
            this.preloadConversationId = this.$route.query.conId
        }
        this.getRecallSummaryById()
        this.getEmail('INFORMATION_MAILS')
        this.$emit('onReLoadCommunicationData')
        this.$emit('onReLoadHistoryData')
        if (this.isProviderCreatedRecall) {
            this.isShowInternal = true
        }
        this.getUserData()
    },
    mounted() {
        this.getRecallConversations().then(() => {
            if (this.conversations.length > 0 && this.conversations[0] && this.conversations[0].id) {
                this.getConversationMessages(this.preloadConversationId ? this.preloadConversationId : this.conversations[0].id)
            }
        })
        this.$nextTick(() => {
            this.resizeEvent()
            window.addEventListener('resize', this.resizeEvent)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeEvent)
    },
    methods: {
        getUserData() {
            if (this.$store.state.user != '') {

                var userData = this.$store.state.user
                this.admin.name = userData.fname + ' ' + userData.lname
                this.admin.email = userData.email
                this.admin.title = userData.title
                this.admin.companyname = userData.org_name
                this.admin.phoneno = userData.phone ?? ''
                this.admin.userSite = userData.userSite ?? ''
            }
            else {
                this.admin.name = ''
                this.admin.email = ''
                this.admin.title = ''
                this.admin.companyname = ''
                this.admin.phoneno = ''
                this.admin.userSite = ''
            }
        },
        getRecallSummaryById() {
            recallServices.getRecallSummaryById(this.communicationId, this.providerCommunicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {

                    this.items = resp.data.d
                }
            })
        },
        getEmailList() {
            recallServices.GetRecallAction_ProviderEmailList(this.$store.state.orgId, this.communicationId).then(resp => {
                if (!resp.error) {
                    this.autocompleteItems = []
                    resp.data.d.forEach((val) => {
                        this.autocompleteItems.push({ text: val.email, is_dl: val.is_dl })
                    })
                }
            })
        },
        removeDocument(index) {
            this.communcationPanelHeight = this.communcationPanelHeight - 60
            this.filesUpload.splice(index, 1)
        },
        addCheck(val) {
            if (val.text) {
                this._showToast('The email has been already used.')
            }
            else {
                if (val.length > 0) {
                    this.value = []
                    val.forEach(x => {
                        if (x.is_dl == true) {
                            const tag = {
                                email: x.text,
                                is_dl: x.is_dl,
                            }
                            this.value.push(tag)
                        } else {
                            const tag = {
                                email: x.text,
                                is_dl: false,
                            }
                            this.checkEmailValidation(tag)
                            if (!this.emailError) {
                                this.options.push(tag)
                                this.value.push(tag)
                                this.checkDomainValidation(tag)
                            }
                        }
                    })
                    if (this.value.length > 1) {
                        var valueOriginal = this.value.length
                        var valcurrent = val.length - 1
                        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                        var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                        if (b) {
                            var i = 0
                            for (i = 0; i < valueOriginal - 1; i++) {

                                if (this.value[i].email.trim() === val[valcurrent].text.trim()) {
                                    this.emailError = 'The email has been already used.'
                                }
                            }

                        }
                    }

                }
                else {
                    this.value = []
                    this.emailError = ''
                    this.domainError = ''
                }
            }
        },
        checkDomainValidation(tag) {
            this.domainError = ''
            recallServices.getDamain(tag.email, this.$store.state.orgId).then(resp => {
                if (resp.error) {
                    this.domainError = 'domainError'
                    this.options.pop(tag)
                    this.value.pop(tag)
                    return false
                }
                else {
                    return true
                }
            })
        },
        createDelegate() {
            //Add for domain validation
            if (this.domainError == 'domainError') {
                return this._showToast('The email domain is not valid.', { title: 'Error', variant: 'danger' })

            }
            this.DelegatId = 1
            var htmls = this.bodyContent

            //Add duplicate email validation
            if (this.value.length > 1) {
                for (var i = 0; i < this.value.length; i++) {
                    for (var k = i + 1; k < this.value.length; k++) {
                        if (this.value[i].email.trim() === this.value[k].email.trim()) {
                            return this.emailError = 'The email has been already used.'
                        }

                    }

                }
            }
            //end

            if (this.value.length == 0) {
                return this.emailError = 'Email is required'
            }
            else { this.emailError = '' }
            let formData = new FormData()
            for (let k = 0; k < this.value.length; k++) {
                if (this.value[k].is_dl)
                    formData.append('distributionlist', this.value[k].email)
                else
                    formData.append('emaillist', this.value[k].email)
            }
            formData.append('communicationcontent', htmls)
            this.filesUpload.forEach((x, i) => {
                formData.append('document', x.file)
            })
            formData.append('communicationName', (this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName))
            formData.append('replacedelegateurl', '<a href="${Url}">' + (this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName) + '</a>')

            recallServices.providerInfoMail(this.communicationId, this.DelegatId, formData, this.providerCommunicationId, this.communicationType).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                    this.getProviderInfoMail()
                    this.filesUpload = []
                    this.valueTag = []
                    this.getEmailList()
                    if (this.$route.query.conId && this.$route.query.conId > 0) {
                        this.preloadConversationId = this.$route.query.conId
                    }
                    this.getEmail('INFORMATION_MAILS')
                    this.isShowInternal = true
                }
            })
            this.item.comment = ''
            this.value = ''
        },
        checkEmailValidation(tag) {
            if (!tag) {
                this.emailError = 'Email is required'
            }
            else { this.emailError = '' }
            var currentTag = tag
            if (currentTag) {
                const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                var b = re.test(String(tag.email).toLowerCase())
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                }
            }
        },
        getAttachementByEmail(emailname, delegateId) {
            this.selectedDelegateId = delegateId
            this.isShowToolbarOptions = 'false'
            this.hideattachement = 'true'
            recallServices.getAttachementByEmail(this.communicationId, this.$store.state.orgId, emailname, delegateId).then(respattach => {
                if (!respattach.error) {
                    respattach.data.d[0].data_content=respattach.data.d[0].data_content?.replaceAll('${CommunicationType}',getCommunicationType(this.communicationType))
                    this.bodyContent = ''
                    this.filesUpload = ''
                    this.showBtn = false
                    if (respattach.data.d[0].name != null) { this.filesUpload = respattach.data.d }
                    else { this.filesUpload = 0 }

                    if (this.items == null) {
                        recallServices.getRecallSummaryById(this.communicationId, this.providerCommunicationId).then(resp1 => {
                            if (!resp1.error) {
                                this.items = resp1.data.d
                                var bodyReplace = /<p.*?>([\s\S]*)<\/p>/.exec(respattach.data.d[0].data_content.replace('${ProviderUserName}', this.$store.state.user.full_name).replace('${ProviderRole}', this.$store.state.user.role).replace('${ProviderName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName).replace('${Comments}', '')
                                    .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                                )[1]
                                bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>', '')
                                this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.communicationName)
                            }
                        })

                    }
                    else {
                        this.bodyContent = ''
                        var obj = JSON.parse(respattach.data.d[0].data_content)
                        var array = obj.CommunicationContent.split('<span>')
                        if (obj.CommunicationContent.includes('<p style="display: block !important;">Hello,') || obj.CommunicationContent.includes('<p>Hello,')) {
                            var bodyReplace = /<p.*?>([\s\S]*)<\/p>/.exec(respattach.data.d[0].data_content.replace('${ProviderUserName}', this.$store.state.user.full_name).replace('${ProviderRole}', this.$store.state.user.role).replace('${ProviderName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName).replace('${Comments}', '').replace('${CommunicationContent}', '').replaceAll('${displayOld}', ''))[1]
                            bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>', '')
                            this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.communicationName).replaceAll('<p style="display: block;margin-bottom: -10px;">', '<p>')
                        }
                        else {
                            array.forEach(el => {
                                if (el != '')
                                    this.bodyContent += '<p>' + el.replace('</span>', '') + '</p>'
                            })
                            this.bodyContent = this.bodyContent.replaceAll('<p><br></p>', '')
                            this.bodyContent = this.bodyContent.replaceAll('<br>' + this.$store.state.user.full_name, this.$store.state.user.full_name)
                            this.bodyContent = this.bodyContent.replaceAll('<p>', '<p style="margin-bottom: 0px;margin-top:0px">')
                        }
                    }


                }

            })
        },
        getEmail(type) {
            this.emailError = ''
            this.options = []
            this.valueTag = []
            this.value = []
            this.domainError = ''
            this.selectedDelegateId = ''
            this.isShowToolbarOptions = 'true'
            this.filesUpload = []
            type = 'INFORMATION_MAILS'
            recallServices.getEmail(type).then(resp => {
                if (!resp.error) {
                    this.emailItem = resp.data.d
                    this.emailItem.content=resp.data.d.content.replaceAll('${CommunicationType}',getCommunicationType(this.communicationType))
                    this.showBtn = true
                    if (this.items == null) {
                        recallServices.getRecallSummaryById(this.communicationId, this.providerCommunicationId).then(resp1 => {
                            if (!resp1.error) {
                                this.items = resp1.data.d
                                var bodyReplace = /<body.*?>([\s\S]*)<\/body>/.exec(resp.data.d.content.replace('${ProviderUserName}', this.$store.state.user.full_name).replace('${ProviderRole}', this.$store.state.user.role).replace('${ProviderName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : resp1.data.d.recallsitecount > 1 ? this.items.communicationName + ' - ' + resp1.data.d.recallsitename : this.items.communicationName).replace('${Comments}', '')
                                    .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                                )[1]
                                if (this.admin.title == undefined || this.admin.title == null) {
                                    bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;display: block !important;">${ProviderUserTitle}</p>', '')
                                    bodyReplace = bodyReplace.replace('<p style="display: block !important; margin-bottom: 0px; margin-top: 0px;">${ProviderUserTitle}</p>', '')
                                    bodyReplace = bodyReplace.replace('${ProviderUserTitle}', ' ')
                                }
                                else
                                {
                                    bodyReplace = bodyReplace.replace('${ProviderUserTitle}', this.admin.title)
                                }
                                if (this.admin.userSite == undefined || this.admin.userSite == null) {
                                    bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserSite}</p>', '')
                                    bodyReplace = bodyReplace.replace('${ProviderUserSite}', ' ')
                                }
                                else
                                {
                                    bodyReplace = bodyReplace.replace('${ProviderUserSite}', this.admin.userSite)
                                }

                                if (this.admin.email == undefined || this.admin.email == null) {
                                    bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserEmailAddress}</p>', '')
                                    bodyReplace = bodyReplace.replace('${ProviderUserEmailAddress}', ' ')
                                }
                                else
                                {
                                    bodyReplace = bodyReplace.replace('${ProviderUserEmailAddress}', this.admin.email)
                                }

                                if (this.admin.phoneno == undefined || this.admin.phoneno == null) {
                                    bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserPhoneNumber}</p>', '')
                                    bodyReplace = bodyReplace.replace('${ProviderUserPhoneNumber}', ' ')
                                }
                                else
                                {
                                    var x = this.admin.phoneno.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                    var newvalue = !x[2] ? x[1] :  x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
                                    bodyReplace = bodyReplace.replace('${ProviderUserPhoneNumber}', newvalue)
                                }


                                //this.admin.userSite = userData.userSite ?? ''
                                bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>', '')
                                this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : resp1.data.d.recallsitecount > 1 ? this.items.communicationName + ' - ' + resp1.data.d.recallsitename : this.items.communicationName)
                                this.bodyContent = this.bodyContent.replace('<span id="repTxt" style="display: block', '<span id="repTxt" style="display: inline-block')
                                this.hideattachement = 'false'
                            }
                        })
                    }
                    else {
                        var bodyReplace = /<body.*?>([\s\S]*)<\/body>/.exec(resp.data.d.content.replace('${ProviderUserName}', this.$store.state.user.full_name).replace('${ProviderRole}', this.$store.state.user.role).replace('${ProviderName}', this.$store.state.user.org_name).replace('${SupplierName}', this.items == null ? '' : this.items.supplier_name).replace('${EventName}', this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName).replace('${Comments}', '')
                            .replace('${CommunicationContent}', '').replaceAll('${displayOld}', 'block !important')
                        )[1]
                        if (this.admin.title == undefined || this.admin.title == null) {
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;display: block !important;">${ProviderUserTitle}</p>', '')
                            bodyReplace = bodyReplace.replace('<p style="display: block !important; margin-bottom: 0px; margin-top: 0px;">${ProviderUserTitle}</p>', '')
                            bodyReplace = bodyReplace.replace('${ProviderUserTitle}', '')
                        }
                        else
                        {
                            bodyReplace = bodyReplace.replace('${ProviderUserTitle}', this.admin.title)
                        }
                        if (this.admin.userSite == undefined || this.admin.userSite == null || this.admin.userSite == '') {
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserSite}</p>', '')
                            bodyReplace = bodyReplace.replace('<p style="display: block !important; margin-bottom: 0px; margin-top: 0px;">${ProviderUserSite}</p>', '')
                            bodyReplace = bodyReplace.replace('${ProviderUserSite}', ' ')
                        }
                        else
                        {
                            bodyReplace = bodyReplace.replace('${ProviderUserSite}', this.admin.userSite)
                        }

                        if (this.admin.email == undefined || this.admin.email == null) {
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserEmailAddress}</p>', '')
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px; display: block !important;">${ProviderUserEmailAddress}</p>', '')
                            bodyReplace = bodyReplace.replace('${ProviderUserEmailAddress}', ' ')
                        }
                        else
                        {
                            bodyReplace = bodyReplace.replace('${ProviderUserEmailAddress}', this.admin.email)
                        }

                        if (this.admin.phoneno == undefined || this.admin.phoneno == null) {
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px;">${ProviderUserPhoneNumber}</p>', '')
                            bodyReplace = bodyReplace.replace('<p style="margin-bottom: 0px; margin-top: 0px; display: block !important;">${ProviderUserPhoneNumber}</p>', '')
                            bodyReplace = bodyReplace.replace('${ProviderUserPhoneNumber}', ' ')
                        }
                        else
                        {
                            var x = this.admin.phoneno.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                            var newvalue = !x[2] ? x[1] :  x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
                            bodyReplace = bodyReplace.replace('${ProviderUserPhoneNumber}', newvalue)
                        }


                        bodyReplace = bodyReplace.replaceAll('<p style="display: block !important;"><br></p>', '')
                        this.bodyContent = bodyReplace.replace('${EventName}', this.items == null ? '' : this.items.recallsitecount > 1 ? this.items.communicationName + ' - ' + this.items.recallsitename : this.items.communicationName)
                        this.bodyContent = this.bodyContent.replace('<span id="repTxt" style="display: block', '<span id="repTxt" style="display: inline-block')
                        this.hideattachement = 'false'
                    }
                }
            })
        },
        initLoadMessage() {
            this.messagesF = []
            if (this.messages && this.messages.msg) {
                this.messages.msg.forEach(el => {
                    this.addMessage(el)
                })
            }
        },
        addMessage(el) {
            if (this.messagesF.length > 0) {
                var lastItem = this.messagesF[this.messagesF.length - 1]
                var lastMsgTime = null
                if (lastItem.msg.length > 0) {
                    lastMsgTime = lastItem.msg[lastItem.msg.length - 1].time
                }
                var day = null
                if (lastMsgTime) {
                    day = moment.unix(lastMsgTime)
                }
                if (lastMsgTime && day.isSame(moment.unix(el.t), 'day')) {
                    if (lastItem.user_id && lastItem.user_id == el.i) {
                        lastItem.msg.push({
                            type: el.s,
                            time: el.t,
                            content: this.tryDeserializeJson(el.c, el.s)
                        })
                    } else {
                        this.pushNew(el)
                    }
                } else {
                    this.messagesF.push({
                        time_separator: el.t
                    })
                    this.pushNew(el)
                }
            } else {
                this.pushNew(el)
            }
            this.$nextTick(() => {
                var chatWrap = this.$refs['chat-wrap']
                chatWrap.scrollTop = chatWrap.scrollHeight
            })
        },
        pushNew(el) {
            var user = null
            if (this.messages && this.messages.users) {
                user = this.messages.users.find(x => x.id == el.i)
            }
            this.messagesF.push({
                user_id: el.i,
                me: el.i == this.$store.state.user.id ? true : false,
                img: user && user.img ? user.img : null,
                full_name: user && user.fn ? user.fn : null,
                org_name: user && user.org ? user.org : null,
                title: user && user.title ? user.title : null,
                email: user && user.email ? user.email : null,
                phone: user && user.phone ? user.phone : null,
                org_type: user && user.org_type ? user.org_type : null,
                msg: [{
                    type: el.s,
                    time: el.t,
                    content: this.tryDeserializeJson(el.c, el.s)
                }]
            })
        },
        tryDeserializeJson(str, type) {
            if (type == 'Text') {
                return str
            }
            try {
                return JSON.parse(str)
            } catch (e) {
                return []
            }
        },
        resizeEvent() {
            if (window.innerWidth <= 767) {
                this.height = '300px'
            } else {
                var cal = window.innerHeight - this.$refs.chat.getBoundingClientRect().y - 9
                this.height = cal >= 300 ? cal + 'px' : '300px'
            }
        },
        getRecallConversations() {
            return new Promise((resolve, reject) => {
                recallServices.getRecallConversations(this.communicationId, this.providerCommunicationId).then(resp => {
                    if (!resp.error) {
                        this.conversations = resp.data.d
                        this.supplierId = resp.data.d.length > 0 ? resp.data.d[0].supplierid : 0
                        resolve()
                    }
                }).finally(() => {
                    reject()
                })
            })
        },
        getProviderInfoMail() {
            return new Promise((resolve, reject) => {

                recallServices.getProviderMail(this.communicationId, this.$store.state.orgId, this.providerCommunicationId).then(resp => {
                    if (!resp.error) {
                        this.providerInfoMailList = resp.data.d
                        resolve()
                    }
                }).finally(() => {
                    reject()
                })
            })
        },
        async createRecallConversation() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                recallServices.createRecallConversation(this.communicationId, this.communicationType, this.providerCommunicationId, this.supplierId, { name: this.conversationName, msg: this.conversationMsg }).then(resp => {
                    if (!resp.error) {
                        this.getRecallConversations()
                        this.getConversationMessages(resp.data.d.data)
                        this.conversationName = null
                        this.conversationMsg = null
                        this.$refs.createConDialog.hide()
                        this._showToast('Created Successfully!')
                    }
                })
            }
        },
        updateRecallConversation() {

            recallServices.updateRecallConversation(this.communicationId, this.communicationType, this.conversationEditing).then(resp => {
                if (!resp.error) {
                    this.getRecallConversations()
                    this._showToast('Updated Successfully!')
                    this.cancellUpdate()
                }
            })
        },
        cancellUpdate() {
            this.conversationEditing.name = this.previousValue
            this.conversationEditing = {
                id: null,
                name: null
            }
        },
        editConversation(item) {

            this.previousValue = item.name
            if (item.createdUserId == this.$store.state.user.id && item.supplierid == 0) {
                this.conversationEditing = item
            }
            else {
                this._showToast('Cannot edit conversation name created by other users', { variant: 'danger' })
            }
        },
        removeUnreadMessages(num){
            if(num == null || num == undefined || num == 0)
                return
            this.$parent.$parent.$parent.item.unread_msg = this.$parent.$parent.$parent.item.unread_msg - num
        },
        getConversationMessages(conversationId) {
            recallServices.getRecallConversation(this.communicationId, conversationId, this.providerCommunicationId).then(resp => {
                if (!resp.error) {
                    this.conversations.forEach(el => {
                        if (el.id == conversationId) {
                            this.removeUnreadMessages(el.unread)
                            el.unread = 0
                        }
                    })
                    this.messages = resp.data.d
                    this.initLoadMessage()
                }
            })
        },
        sendMessage(type, files = null) {
            if (this.messages.id > 0) {
                let formData = new FormData()
                let isValid = true
                formData.append('messageContent', this.messageContent ? this.messageContent : '')
                formData.append('messageType', type)
                if (files && _.isArray(files)) {
                    files.forEach(file => {
                        formData.append('files', file)
                    })
                }
                if ((type == 'Image' || type == 'File') && files.length == 0) {
                    isValid = false
                }
                if (isValid) {
                    recallServices.createRecallConversationMessage(this.communicationId, this.communicationType, this.messages.id, formData, this.providerCommunicationId).then(resp => {

                        if (resp.data.ok == true) {
                            this.addMessage(resp.data.d.data)
                            this.messageContent = null
                        } else {
                            this._showToast('Send Failed!', { variant: 'danger' })
                        }
                    })
                }
            }
        },
        uploadPhoto(files) {
            if(files && files.length > 0)
            {
                this.sendMessage('Image', files)
            }
        },
        uploadAttachmentFile(files) {
            this.filesUpload.push({
                name: files.currentTarget.files[0].name,
                size: files.currentTarget.files[0].size,
                type: files.currentTarget.files[0].type,
                file: files.currentTarget.files[0],
            })
            files.currentTarget.value = ''
        },
        uploadFile(files) {
            if(files && files.length > 0)
            {
                this.sendMessage('File', files)
            }
        },
        ViewClick(val) {
            let itemVal = {
                extension: val.extension,
                filename: val.name,
                signed_url: val.signed_url,
                signed_url_review: val.signed_url_review,
                fileSize: val.size,
                mimetype: val.type
            }
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(itemVal)
            })
        },
        downloadFile(fileId) {
            recallServices.getRecallConversationMessageFile(this.communicationId, this.messages.id, fileId).then(resp => {
                if (!resp.error && resp.data && resp.data.d && resp.data.d.signed_url) {
                    this.ViewClick(resp.data.d)
                    //this.openExternalLink(resp.data.d.signed_url)
                }
            })
        },
    }
}